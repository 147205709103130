export const plans = [
    {
        id: 1,
        name: "Particulier",
        price: 0,
        validity_days: 30,
        max_ads: 3,
        max_photos: 3,
        visibility: "Basique",
        support: "Basique",
        content: {
            ads_num: 'Limité',
            cost_plan: 'Gratuit',
            ads_visible: 'Standard',
            support_client: 'Basique',
            stat_performance: 'Non',
            special_cat: 'Non',
            tool_manage_ads: 'Oui',
            personalize: 'Partielle',
            credibility: 'Moyenne',
            ads_status: 'Non inclus',
        }
    },
    {
        id: 2,
        name: "Professionnel",
        price: 2000,
        validity_days: 30,
        max_ads: 10,
        max_photos: 9,
        visibility: "Améliorée",
        support: "Prioritaire",
        content: {
            ads_num: 'Élevé',
            cost_plan: 'Moyen à élevé',
            ads_visible: 'Boostée',
            support_client: 'Prioritaire',
            stat_performance: 'Basique',
            special_cat: 'Oui',
            tool_manage_ads: 'Oui',
            personalize: 'Moyenne',
            credibility: 'Bonne',
            ads_status: 'Inclus : 7x, 24h/durée',
        }
    },
    {
        id: 3,
        name: "Entreprise",
        price: 4000,
        validity_days: 30,
        max_ads: 20,
        max_photos: 12,
        visibility: "Premium",
        support: "VIP",
        content: {
            ads_num: 'illimité',
            cost_plan: 'Élevé',
            ads_visible: 'Maximum',
            support_client: 'VIP',
            stat_performance: 'Avancé',
            special_cat: 'Oui',
            tool_manage_ads: 'Oui',
            personalize: 'Complète',
            credibility: 'Excellente',
            ads_status: 'Inclus : 14x, 24h/durée',
        }
    }
]; 